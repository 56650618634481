<template>
  <div>
    <docu-about ref="docuAbout"></docu-about>
  </div>
</template>

<script>
import DocuAbout from '@/views/component/docuCommon/docuAbout';

export default {
  name: 'FequAddAbout',
  components: { DocuAbout },
  methods: {
    trigger() {
      this.$refs.docuAbout.getDocus();
    }
  }
};
</script>

<style scoped></style>
