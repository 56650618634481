<template>
  <div style="display: flex">
    <slot name="elForm"></slot>
    <div style="display: flex; flex-direction: column; justify-content: center">
      <el-tooltip effect="dark" content="复制" placement="top">
        <el-link
          class="vg_cursor"
          :disabled="isShow"
          :underline="false"
          icon="el-icon-document-copy"
          @click="copyCell(`${column}[copyDataFlag]${copyData}[copyDataFlag]${index}[copyDataFlag]${type}`)"
        ></el-link>
      </el-tooltip>
      <span style="height: 9px"></span>
      <el-tooltip effect="dark" content="粘贴" placement="right">
        <el-link :disabled="isShow" :underline="false" class="vg_cursor" icon="el-icon-finished" @click="pasteCell(column, index)"></el-link>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CopyPasteCell',
  props: {
    isShow: {
      type: Boolean,
      default: false,
      required: true
    },
    column: {
      type: String,
      default: '',
      required: true
    },
    type: {
      type: String,
      default: '',
      required: true
    },
    index: {
      type: Number,
      default: 0,
      required: true
    },
    copyData: {
      required: true
    }
  },
  mounted() {},
  methods: {
    copyCell(text) {
      navigator.clipboard.writeText(text);
    },
    pasteCell(column, index) {
      navigator.clipboard
        .readText()
        .then(text => {
          let textSplit = text.split('[copyDataFlag]');
          if (textSplit.length > 1 && textSplit[0] === column) {
            if (Number(textSplit[2]) === index) return;
            let rangeIndexArr;
            if (Number(textSplit[2]) > index) rangeIndexArr = [index, Number(textSplit[2])];
            else rangeIndexArr = [Number(textSplit[2]), index];
            this.$emit('changeData', { rangeIndexArr: rangeIndexArr, textSplit: textSplit, column: column });
          }
        })
        .catch(err => {
          console.error('无法读取剪贴板: ', err);
        });
    },
    changeData(data) {
      data.list?.forEach((item, tIndex) => {
        if (tIndex >= data.rangeIndexArr[0] && tIndex <= data.rangeIndexArr[1]) {
          if (data.textSplit[3] === 'number') {
            this.$set(item, data.column, Number(data.textSplit[1]));
          } else {
            this.$set(item, data.column, data.textSplit[1]);
          }
          if (data.method) data.method(tIndex, data.methodArgs?.arg2);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
