import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const fequAPI = {
  getFequs: topic.fequTopic + '/get_fequs',
  getFequById: topic.fequTopic + '/get_fequ_by_id',
  addFequ: topic.fequTopic + '/add_fequ',
  editFequ: topic.fequTopic + '/edit_fequ',
  deleteFequByIds: topic.fequTopic + '/delete_fequ_by_ids',
  getFequSmplByFequId: topic.fequTopic + '/get_fequ_smpl_by_fequ_id',
  addSampleControlTable: params => getNoCatch(`${topic.fequTopic}/addSampleControlTable`, params),
  editSampleControlTable: params => postNoCatch(`${topic.fequTopic}/editSampleControlTable`, params),
  getMakeSampleControlTableList: params => getNoCatch(`${topic.fequTopic}/getSampleControlTable`, params),
  getRequestSampleList: params => getNoCatch(`${topic.fequTopic}/getRequestSampleList`, params),
  exportSampleControlTable: params => getNoCatch(`${topic.fequTopic}/exportSampleControlTable`, params),
  export_fequ_part: params => getNoCatch(`${topic.fequTopic}/export_fequ_part`, params),
  fequPush9003List: topic.fequTopic + '/fequPush9003List',
  getfequPush9009List: topic.fequTopic + '/getfequPush9009List',
  haveKnown: topic.fequTopic + '/haveKnown',
  deleteFequPush9003: topic.fequTopic + '/deleteFequPush9003',
  fequPush9003Total: topic.fequTopic + '/fequPush9003Total',
  requPush9004Total: topic.requTopic + '/requPush9004Total',
  getCustFequStatisticsM: params => getNoCatch(`${topic.fequTopic}/getCustFequStatisticsM`, params),
  getCustFequStatisticsBF: params => getNoCatch(`${topic.fequTopic}/getCustFequStatisticsBF`, params),
  exportCustFequStatisticsM: params => getNoCatch(`${topic.fequTopic}/exportCustFequStatisticsM`, params),
  exportCustFequStatisticsBF: params => getNoCatch(`${topic.fequTopic}/exportCustFequStatisticsBF`, params),
  getFequSmplList: params => getNoCatch(`${topic.fequTopic}/getFequSmplList`, params),
  editFequSmplPush: params => postNoCatch(`${topic.fequTopic}/editFequSmplPush`, params),
  editReturnRequ: params => postNoCatch(`${topic.fequTopic}/editReturnRequ`, params),
  editFequSmole: params => postNoCatch(`${topic.fequTopic}/editFequSmole`, params),
  getfequPush9008: params => getNoCatch(`${topic.fequTopic}/getfequPush9008`, params),
  getfequPush9008Total: params => getNoCatch(`${topic.fequTopic}/getfequPush9008Total`, params),
  getfequPush9009Total: params => getNoCatch(`${topic.fequTopic}/getfequPush9009Total`, params),
  delete9008: params => getNoCatch(`${topic.fequTopic}/editfequPush9008`, params),
  copy_fequ_by_id: params => getNoCatch(`${topic.fequTopic}/copy_fequ_by_id`, params),
  editRequestSampleList: params => postNoCatch(`${topic.fequTopic}/editRequestSampleList`, params),
  ecportRequestSampleList: params => getNoCatch(`${topic.fequTopic}/ecportRequestSampleList`, params),
  getRequestSampleSuppList: params => getNoCatch(`${topic.fequTopic}/getRequestSampleSuppList`, params)
};
