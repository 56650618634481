<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <editHeader
      v-sticky
      style="padding-top: 16px; background: white"
      :isShow="isShow"
      :btn="btn"
      :strForm="fequForm"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @refresh="buttonRefresh"
      @submit="submit('fequForm')"
    >
      <div slot="functionButton" class="vg_ml_8" v-if="isShow">
        <el-button
          v-if="isShow && showFlag && fequForm.order_type === 0"
          :disabled="fequForm.status !== 2"
          size="small"
          type="warning"
          @click="importManuscript"
          >生成二部报价明细
        </el-button>
        <el-button v-if="isShow" :disabled="fequForm.status !== 2" plain size="small" @click="importManuscript2"> 生成简易报价单 </el-button>
        <el-button size="small" type="primary" @click="downloadPdf">下载PDF</el-button>
        <el-button v-if="!showFlag" :disabled="fequForm.status !== 2 || fequForm.fequ_state === 2" size="small" type="success" @click="synchronizationLogin"
          >同步至打样间
        </el-button>
        <el-button v-if="fequForm.fequ_dyj_status === 1 && fequForm.requ_type === 1" size="small" type="primary" @click="updateAttachmentsToDYJ"
          >更新附件至打样间
        </el-button>
      </div>
      <div slot="otherButton">
        <!--        <el-tag type="success" v-if="fequForm.fequ_back_status === 0" size="small" class="vg_mr_8"> 未退回</el-tag>
        <el-tag type="danger" v-if="fequForm.fequ_back_status === 1" size="small" class="vg_mr_8"> 已退回</el-tag>-->
        <span style="margin-right: 2px">工厂打样单号:</span>
        <el-input size="small" style="width: 150px" class="vg_mr_8" v-model="fequForm.fequ_no" disabled show-word-limit></el-input>
      </div>
    </editHeader>
    <el-form ref="fequForm" :model="fequForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="委托打样单号" prop="requ_no">
            <el-input maxlength="30" v-model="fequForm.requ_no" disabled placeholder="请填写委托设计单号" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商简称" prop="supp_id">
            <el-select @change="suppIdChange" filterable v-model="fequForm.supp_id" placeholder="请选择供应商简称">
              <el-option v-for="item in fequStppList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="同步打样间状态" prop="fequ_dyj_status">
            <!--              <el-tag type="success" v-if="fequForm.fequ_dyj_status === 1"> 已同步 </el-tag>-->
            <!--              <el-tag type="danger" v-else class="vg_mr_8"> 未同步 </el-tag>-->
            <el-input :value="fequForm.fequ_dyj_status === 1 ? '已同步' : '未同步'" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="累计订单量" prop="supp_ordr_total">
            <el-input v-model="fequForm.supp_ordr_total" disabled maxlength="30" placeholder="选择供应商自动带入" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户简称" prop="cust_abbr">
            <el-input maxlength="10" disabled v-model="fequForm.cust_abbr" placeholder="请填写客户简称" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="打样进度" prop="fequ_state">
            <el-select v-model="fequForm.fequ_state" :disabled="fequForm.requ_type === 1" filterable placeholder="请选择打样状态">
              <el-option v-for="item in fequStateList" :key="item.id" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="供应商联系人">
            <el-input disabled maxlength="30" v-model="fequPhoneNo" placeholder="选择供应商自动带入" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="要求交样日期" prop="requ_pedate">
            <el-date-picker
              @change="requPedateChange"
              v-model="fequForm.requ_pedate"
              type="date"
              :picker-options="pickerOptions"
              placeholder="选择要求交样日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="是否提供原样" prop="requ_sample">
            <el-radio disabled v-model="fequForm.requ_sample" label="1">是</el-radio>
            <el-radio disabled v-model="fequForm.requ_sample" label="0">否</el-radio>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="cptt_name">
            <el-input disabled v-model="fequForm.cptt_name" show-word-limit placeholder="暂无公司抬头"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item :label="$cookies.get('userInfo').dept_name === '辅料部' ? '实际完成日期' : '实际交样日期'" prop="fequ_smpl_redate">
            <el-date-picker
              v-model="fequForm.fequ_smpl_redate"
              type="date"
              placeholder="选择要求交样日期"
              @change="fequSmplRedateClick(1)"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="实际核价日期" prop="requ_smpl_requdate">
            <el-date-picker
              v-model="fequForm.requ_smpl_requdate"
              type="date"
              placeholder="选择要求交样日期"
              value-format="timestamp"
              @change="fequSmplRedateClick(2)"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="16">
          <el-col :md="24">
            <el-form-item label="样品要求" prop="requ_content">
              <el-input v-model="fequForm.requ_content" :rows="2" placeholder="请填写样品要求" show-word-limit type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" v-if="fequForm.requ_type === 1">
            <el-form-item label="DYJ退单原因" prop="requ_DYJreason">
              <el-input v-model="fequForm.requ_DYJreason" :rows="2" disabled placeholder="请填写" show-word-limit type="textarea"></el-input>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :md="8" v-if="fequForm.requ_type !== 3 && fequForm.requ_type !== 0">
          <el-form-item label="打样委托部门" prop="requ_stff_dept_name">
            <el-input disabled v-model="fequForm.requ_stff_dept_name" show-word-limit placeholder="暂无打样委托部门"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="打样委托人" prop="requ_stff_name">
            <el-input maxlength="10" disabled v-model="fequForm.requ_stff_name" placeholder="请填写打样委托人" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="核价接收人" prop="requ_pricer">
            <!--<el-input maxlength="10" disabled v-model="fequForm.requ_pricer_name" placeholder="请填写核价委托人" show-word-limit></el-input>-->
            <el-select v-model="fequForm.requ_pricer" clearable filterable size="small">
              <el-option v-for="item in userList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="报价接收人" prop="requ_quoter">
            <!--<el-input maxlength="10" disabled v-model="fequForm.requ_quoter_name" placeholder="请填写报价委托人" show-word-limit></el-input>-->
            <el-select v-model="fequForm.requ_quoter" clearable filterable placeholder="请选择" size="small">
              <el-option v-for="item in userList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <div class="vg_mtb_16 vd_bort flexV">
          <el-button size="small" type="primary" @click="dialogVisible = true">导入样品明细</el-button>
          <el-button size="small" plain type="danger" @click="deleteRequ">删除</el-button>
          <!--          <el-button size="small" plain type="success" @click="subFequ">确认收样</el-button>
          <el-button size="small" plain type="success" @click="subRequdate">确认核价</el-button>-->
          <el-form :disabled="false" class="vg_ml_8 flexV" size="mini">
            <el-button v-if="fequForm.order_type === 0 && fequForm.requ_type === 1" size="small" type="danger" @click="backDyjRequ">退小车间样</el-button>
            <el-button
              v-if="fequForm.order_type === 0 && fequForm.requ_type === 1 && fequForm.fest_smpl_list.filter(x => x.sample_status === 2).length > 0"
              size="small"
              type="danger"
              @click="cancelSampling"
              >取消打样</el-button
            >
            <el-button
              v-if="fequForm.order_type === 0 && fequForm.requ_type === 1 && fequForm.fest_smpl_list.filter(x => x.sample_status === 4).length > 0"
              size="small"
              type="danger"
              @click="returnTheCommissionedSampleForm"
              >退回委托打样单</el-button
            >
          </el-form>
          <div v-if="$cookies.get('userInfo').dept_name === '辅料部'">
            完成日期:
            <el-date-picker
              v-model="sampleSubmissionDate"
              class="vg_ml_8 vg_mr_8 topDateRangeSearch"
              placeholder="请选择"
              size="small"
              type="date"
              value-format="timestamp"
            />
            <el-button plain size="small" type="success" @click="fillSubTableDate">填充</el-button>
          </div>
        </div>
        <div>
          <RequList ref="RequList" :fequForm="fequForm" :is-show="isShow" @handleSelectionChange="handleSelectionChange"></RequList>
          <!--          <RequLintList-->
          <!--            v-if="fequForm.requ_type !== 3 && fequForm.requ_type !== 0"-->
          <!--            @handleSelectionChange="handleSelectionChange"-->
          <!--            :fequForm="fequForm"-->
          <!--          ></RequLintList>-->
        </div>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="fequForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="70%">
      <RequImport @childRequ="childRequ" @childRequChanel="childRequChanel" :requNo="fequForm.requ_no"></RequImport>
    </el-dialog>

    <el-dialog :title="title1" :visible.sync="dialogVisible1" width="70%">
      <Fequconfirm
        :requNo="fequForm.requ_no"
        :fequNo="fequForm.fequ_no"
        :userList="userList"
        :type="type"
        :fequFormList="fequFormList"
        @fequCancel="fequCancel"
      ></Fequconfirm>
    </el-dialog>
    <el-dialog title="请填写退样原因！" style="text-align: center" :visible.sync="fequBackFlag" width="25%">
      <el-form ref="form" :model="fequ_back" label-width="80px">
        <el-form-item label="原因:" prop="fequ_back_remark" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-input v-model="fequ_back.fequ_back_remark" maxlength="500" placeholder="请填写退样原因" rows="7" show-word-limit type="textarea"></el-input>
          <el-form-item style="text-align: center; margin-top: 10px">
            <el-button type="primary" @click="fequBackSubmit">确认</el-button>
          </el-form-item>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { fequAPI } from '@api/modules/fequ';
import { stffAPI } from '@/api/modules/staff';
import { suppAPI } from '@api/modules/supp';
import { openAPI } from '@api/modules/open';
import { userAPI } from '@api/modules/user';
import editHeader from '@/views/component/editHeaderLine';
import inputUser from '@/views/component/inputUser';
import RequList from './Componet/RequList.vue';
import RequImport from './Componet/RequImport.vue';
import Fequconfirm from './Componet/FequConfirm.vue';
import { getArrayIds } from '@assets/js/arrayUtils';
import { cloneDeep } from 'lodash';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'fequEditMain',
  components: {
    editHeader,
    inputUser,
    RequList,
    RequImport,
    Fequconfirm
  },
  data() {
    return {
      rules: {
        requ_no: [{ required: true, trigger: 'blur', message: ' ' }],
        supp_id: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        fequ_state: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        requ_stff_id: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        requ_pedate: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      fequForm: {
        fequ_id: null,
        fequ_no: null,
        fequ_state: null, //打样状态  （默认）未开始 = 0，进行中 = 1，已完成 = 2 取消打样 = 3
        requ_no: null,
        cust_id: null, // 客户ID
        supp_id: null,
        requ_sample: null,
        requ_content: null,
        cptt_name: null,
        requ_pedate: null, //要求交样日期
        requ_stff_id: null,
        requ_stff_dept_name: null, //打样委托部门
        requ_id: null,
        requ_type: null, //委托类型 1毛绒 2辅材 3非毛绒
        requ_dept_id: null,
        fequ_dyj_status: null, //同步打样间状态0未同步1已同步
        residue_time: null, //剩余需交样天数 = 当前日期-要求交样日期  剩余三天时 用黄色表示剩余2天用橙色剩余一天用红色 ，其余正常
        residue_time_type: null, //1=延迟交样
        stff_id: null, //录入人
        stff_name: null, //录入人名称
        dept_id: null, //录入人部门
        dept_name: null, //录入人部门名称
        fequ_back_num: null, //退单次数
        fest_smpl_list: [],
        docuPtersslist: [],
        fequ_back_status: null,
        status: null,
        requ_stff_name: null,
        supp_ordr_total: null
      },
      fequBackFlag: false,
      fequ_back: {
        fequ_back_remark: null
      },
      show: false,
      isShow: true,
      btn: {},
      stffForm: {},
      loadingFlag: true,
      dialogVisible: false,
      dialogVisible1: false,
      title: '委托打样单',
      title1: '生成二部报价明细',
      fequStppList: [],
      fequPhoneNo: '',
      fequStateList: [
        { id: 0, label: '未开始' },
        { id: 1, label: '进行中' },
        { id: 2, label: '已完成' },
        { id: 3, label: '取消打样' },
        { id: 4, label: '部分取消' }
      ],
      festSmplListCopy: [],
      festSmplLintListCopy: [],
      selectList: [],
      fequFormList: [],
      showFlag: false,
      userList: [],
      type: null,
      synchronizationFlag: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e6;
        }
      },
      sampleSubmissionDate: ''
    };
  },
  created() {
    this.initData();
  },
  computed: {
    name() {
      return this.data;
    }
  },
  watch: {},
  methods: {
    initData() {
      this.loadingFlag = false;
      this.getfequInfo();
      this.getFequSuppList();
      this.getUser();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.show = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      let fequForm = JSON.parse(JSON.stringify(this.fequForm));
      fequForm.requ_pedate = parseInt(new Date(fequForm.requ_pedate).getTime() / 1000);
      fequForm.fequ_id = props.form_id;
      fequForm.personal = this.$cookies.get('userInfo').personal;
      this.festSmplListCopy = this.festSmplListCopy.filter(item => fequForm.fest_smpl_list.every(item1 => item.fequ_smpl_id !== item1.fequ_smpl_id));
      for (let i in this.festSmplListCopy) {
        this.festSmplListCopy[i].destroy_flag = 1;
      }
      for (let i in this.festSmplLintListCopy) {
        this.festSmplLintListCopy[i].destroy_flag = 1;
      }
      for (let i in fequForm.fest_smpl_list) {
        fequForm.fest_smpl_list[i].fequ_pedate = Number(new Date(fequForm.fest_smpl_list[i].fequ_pedate).getTime() / 1000);
        if (fequForm.fest_smpl_list[i].fequ_smpl_redate) {
          fequForm.fest_smpl_list[i].fequ_smpl_redate = Number(new Date(fequForm.fest_smpl_list[i].fequ_smpl_redate).getTime() / 1000);
        } else {
          fequForm.fest_smpl_list[i].fequ_smpl_redate = 0;
        }
        if (!fequForm.fest_smpl_list[i].fequ_taker) {
          fequForm.fest_smpl_list[i].fequ_taker = 0;
        }
        if (!fequForm.fest_smpl_list[i].fequ_pricer) {
          fequForm.fest_smpl_list[i].fequ_pricer = 0;
        }
        //格式化要求核价日期
        if (fequForm.fest_smpl_list[i].requ_smpl_qudate) {
          fequForm.fest_smpl_list[i].requ_smpl_qudate = Number(new Date(fequForm.fest_smpl_list[i].requ_smpl_qudate).getTime() / 1000);
        } else {
          fequForm.fest_smpl_list[i].requ_smpl_qudate = 0;
        }
        //格式化实际核价日期
        if (fequForm.fest_smpl_list[i].requ_smpl_requdate) {
          fequForm.fest_smpl_list[i].requ_smpl_requdate = Number(new Date(fequForm.fest_smpl_list[i].requ_smpl_requdate).getTime() / 1000);
        } else {
          fequForm.fest_smpl_list[i].requ_smpl_requdate = 0;
        }
        if (fequForm.fest_smpl_list[i].fequ_smpl_quodate) {
          fequForm.fest_smpl_list[i].fequ_smpl_quodate = Number(new Date(fequForm.fest_smpl_list[i].fequ_smpl_quodate).getTime() / 1000);
        } else {
          fequForm.fest_smpl_list[i].fequ_smpl_quodate = 0;
        }
      }
      fequForm.fest_smpl_list = fequForm.fest_smpl_list.concat(this.festSmplListCopy);
      post(fequAPI.editFequ, fequForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    getUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.userList = res.data.data;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('fequForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 获取供应商
    getFequSuppList() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.fequStppList = res.data.data;
          }
        })
        .catch(() => {});
    },
    // 同步打样间
    synchronizationLogin() {
      this.$confirm('确定同步至打样间？如已同步则覆盖退单数据至打样间！', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          if (!this.fequForm.belo_dept_id) {
            this.$message.warning('此功能仅限打样间使用');
          } else {
            post(openAPI.fequToDyj, { fequ_id: this.fequForm.fequ_id })
              .then(res => {
                if (res.data.code === 0) {
                  this.$message({ message: res.data.data, type: 'success' });
                  this.synchronizationFlag = true;
                  this.getfequInfo();
                  // this.getUserInfo(res.data.data.form_id);
                } else {
                  let mg = res.data.msg;
                  let tp = 'error';
                  this.$message({ message: mg, type: tp });
                }
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
    // 获取账号
    getUserInfo(val) {
      get(userAPI.getUserById, { user_id: Number(this.$cookies.get('userInfo').user_id) }).then(res => {
        if (res.data.code === 0) {
          let userForm = res.data.data.form;
          let password = userForm.user_pass;
          let vrpKey = UrlEncode.encode(
            JSON.stringify({
              userPass: password
            })
          );
          this.helper.jumpDyj(userForm, vrpKey, val, 2);

          // 本地
          // window.open(`http://localhost:8885/vyj/login?userName=${userForm.user_name}&userPass=${vrpKey}&page=2&formId=${val}&creatime=${userForm.create_time}`)
          // 测试
          // window.open(`https://www.petsprod.com/vyj/login?userName=${userForm.user_name}&userPass=${vrpKey}&page=2&formId=${val}&creatime=${userForm.create_time}`)
          // 线上
          // window.open(`https://www.manenwork.com/login?userName=${userForm.user_name}&userPass=${vrpKey}&page=2&formId=${val}&creatime=${userForm.create_time}`)
          // get(userAPI.userLogout).then(res => {
          //   if (res.data.code === 0) {
          //     this.$cookies.remove('userInfo')
          //     this.$cookies.remove('push')
          //   }
          // })
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // fequCancel
    fequCancel() {
      this.dialogVisible1 = false;
    },
    // 生成二部报价明细
    importManuscript() {
      if (this.fequForm.status === 2) {
        this.fequFormList = this.fequForm.fest_smpl_list;
        this.type = 1;
        this.title1 = '生成二部报价明细';
        this.dialogVisible1 = true;
      } else {
        this.$message.warning('必须生效才可导入');
      }
    },
    importManuscript2() {
      if (this.fequForm.status === 2) {
        this.fequFormList = this.fequForm.fest_smpl_list;
        this.type = 2;
        this.title1 = '生成简易报价单';
        this.dialogVisible1 = true;
      } else {
        this.$message.warning('必须生效才可导入');
      }
    },
    // 更改供应商简称
    suppIdChange(val) {
      this.fequStppList.forEach(item => {
        if (item.supp_id === val) {
          this.fequForm.supp_ordr_total = item.supp_ordr_total;
          this.fequPhoneNo = item.supp_contact + item.supp_phone;
          this.fequForm.supp_contact = item.supp_contact;
          this.fequForm.supp_phone = item.supp_phone;
        }
      });
    },
    // 更改要求交样日期
    requPedateChange(val) {
      for (let i in this.fequForm.fest_smpl_list) {
        if (this.fequForm.requ_type !== 3) {
          this.fequForm.fest_smpl_list[i].fequ_pedate = this.fequForm.requ_pedate;
        }
        this.fequForm.fest_smpl_list[i].fequ_smpl_redate = this.fequForm.requ_pedate;
      }
    },
    // 选中值
    handleSelectionChange(val) {
      let value = JSON.parse(JSON.stringify(val));
      this.selectList = value;
    },
    //确认收样
    subFequ() {
      if (this.selectList.length === 0) {
        return this.$message.warning('至少选择一条信息');
      }
      let time = new Date().getTime();
      for (let i = 0; i < this.selectList.length; i++) {
        for (let j = 0; j < this.fequForm.fest_smpl_list.length; j++) {
          let { fequ_smpl_id, requ_smpl_id } = this.selectList[i];
          let { fequ_smpl_id: fsi, requ_smpl_id: rsi } = this.fequForm.fest_smpl_list[j];
          if (fequ_smpl_id && fequ_smpl_id === fsi) this.$set(this.fequForm.fest_smpl_list[j], 'fequ_smpl_redate', time);
          if (requ_smpl_id && requ_smpl_id === rsi) this.$set(this.fequForm.fest_smpl_list[j], 'fequ_smpl_redate', time);
        }
      }
      // let obj = this.fequForm.requ_type === 3 ? 'fequ_smpl_id' : this.fequForm.requ_type !== 3 && this.fequForm.requ_type !== 0 ? 'fequ_sample_lint_id' : '';
      let obj = 'fequ_smpl_id';
      fequAPI
        .addSampleControlTable({
          fequ_sample_lint_id: getArrayIds(this.selectList, obj).toString(),
          requ_type: this.fequForm.requ_type
        })
        .then(() => {
          this.$message.success('已修改实际收样时间！');
        });
    },
    //确认核价
    subRequdate() {
      if (this.selectList.length === 0) {
        return this.$message.warning('至少选择一条信息');
      }
      let time = new Date().getTime();
      //更改实际核价日期
      for (let i = 0; i < this.selectList.length; i++) {
        for (let j = 0; j < this.fequForm.fest_smpl_list.length; j++) {
          let { fequ_smpl_id, requ_smpl_id } = this.selectList[i];
          let { fequ_smpl_id: fsi, requ_smpl_id: rsi } = this.fequForm.fest_smpl_list[j];
          if (fequ_smpl_id && fequ_smpl_id === fsi) this.$set(this.fequForm.fest_smpl_list[j], 'requ_smpl_requdate', time);
          if (requ_smpl_id && requ_smpl_id === rsi) this.$set(this.fequForm.fest_smpl_list[j], 'requ_smpl_requdate', time);
        }
      }
    },
    //退样弹框显示
    backDyjRequ() {
      if (this.selectList.length === 0) {
        return this.$message.warning('至少选择一条信息');
      }
      this.fequBackFlag = true;
    },
    //退样
    fequBackSubmit() {
      if (!this.fequ_back.fequ_back_remark) {
        return this.$message.warning('原因必填！');
      }
      this.$confirm('确定退样此条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let fequSmplIdList = [];
          this.selectList.forEach(item => {
            fequSmplIdList.push(item.fequ_smpl_id);
          });
          post(openAPI.backDyjRequ, {
            fequSmplIdList: fequSmplIdList,
            fequ_back_remark: this.fequ_back.fequ_back_remark
          })
            .then(res => {
              if (res.data.code === 0) {
                this.fequ_back.fequ_back_remark = null;
                this.fequBackFlag = false;
                this.$message.success(res.data.msg);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    },
    // 删除选中的内容
    deleteRequ() {
      if (this.selectList.length > 0) {
        let temp = [];
        for (let i = 0; i < this.selectList.length; i++) {
          let ind = null;
          this.fequForm.fest_smpl_list.forEach((item, index) => {
            if (item.keys === this.selectList[i].keys) {
              console.log('index', index);
              ind = index;
            }
          });
          temp.push(ind);
        }
        temp = temp.sort((a, b) => {
          return b - a;
        });
        for (let j of temp) {
          this.fequForm.fest_smpl_list.splice(j, 1);
        }
        for (let i in this.fequForm.fest_smpl_list) {
          this.fequForm.fest_smpl_list[i].keys = Number(i);
        }
      } else {
        this.$message({
          message: '至少选择一条数据',
          type: 'warning'
        });
      }
    },
    // 导入新增
    childRequ(val) {
      let value = JSON.parse(JSON.stringify(val));
      let key = this.fequForm.fest_smpl_list.length;
      if (this.fequForm.requ_type !== 3) {
        value.forEach(item => {
          item.fequ_smpl_redate = null;
          item.fequ_smpl_quodate = null;
          item.requ_smpl_qudate = null;
          item.requ_smpl_requdate = null;
        });
      }
      for (let i in value) {
        value[i].fequ_smpl_num = this.helper.reservedDigits(value[i].requ_smpl_num);
        value[i].fequ_smpl_type = value[i].requ_smpl_type;
        value[i].fequ_smpl_made = value[i].requ_smpl_made;
        value[i].fequ_smpl_quot = value[i].requ_smpl_quot;
        if (value[i].requ_smpl_pricer) {
          value[i].fequ_pricer = value[i].requ_smpl_pricer;
        } else {
          value[i].fequ_pricer = null;
        }
        if (value[i].requ_smpl_taker) {
          value[i].fequ_taker = value[i].requ_smpl_taker;
        } else {
          value[i].fequ_taker = null;
        }
        value[i].keys = key;
        value[i].fequ_pedate = this.fequForm.requ_pedate;
        // value[i].fequ_smpl_redate = this.fequForm.requ_pedate
        let flag = this.fequForm.fest_smpl_list.some(item => item.requ_smpl_id === value[i].requ_smpl_id);
        if (!flag) {
          this.fequForm.fest_smpl_list.push(value[i]);
        }
        key++;
      }
      this.dialogVisible = false;
    },
    // 导入取消
    childRequChanel() {
      this.dialogVisible = false;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取表单信息
    getfequInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.showFlag = props.showFlag;
      this.fequForm.fequ_id = props.form_id;
      get(fequAPI.getFequById, { fequ_id: this.fequForm.fequ_id })
        .then(res => {
          if (res.data.code === 0) {
            this.fequForm = res.data.data.form;
            this.festSmplListCopy = JSON.parse(JSON.stringify(this.fequForm.fest_smpl_list));
            this.btn = res.data.data.btn;
            this.stffForm.stff_name = this.fequForm.stff_name;
            this.stffForm.dept_name = this.fequForm.dept_name;
            this.stffForm.dept_team_name = this.fequForm.dept_team_name;
            this.stffForm.user_id = this.fequForm.user_id;
            this.stffForm.dept_id = this.fequForm.dept_id;
            this.stffForm.stff_id = this.fequForm.stff_id;
            this.fequForm.requ_sample = this.fequForm.requ_sample + '';
            if (this.fequForm.requ_pedate !== 0) {
              this.fequForm.requ_pedate = parseInt(Number(this.fequForm.requ_pedate) * 1000);
            } else {
              this.fequForm.requ_pedate = null;
            }
            // if (this.fequForm.create_time === this.fequForm.update_time) {
            //   this.isShow = false;
            //   this.$emit('isShow', this.isShow);
            //   this.fequForm.supp_id = null;
            // } else {
            this.fequPhoneNo = this.fequForm.supp_contact + this.fequForm.supp_phone;
            // }
            // if (this.fequForm.fest_smpl_list[0].fequ_dept_name === '采购一部') {
            //   this.showFlag = false;
            // } else if (this.fequForm.fest_smpl_list[0].fequ_dept_name === '采购二部') {
            //   this.showFlag = true;
            // }
            if (this.fequForm.fest_smpl_list.length !== 0) {
              if (this.fequForm.fest_smpl_list[0].fequ_dept_name === '采购二部') {
                this.showFlag = true;
              }
            }
            for (let i in this.fequForm.fest_smpl_list) {
              this.fequForm.fest_smpl_list[i].keys = Number(i);
              if (this.fequForm.fest_smpl_list[i].fequ_pedate !== 0) {
                this.fequForm.fest_smpl_list[i].fequ_pedate = parseInt(Number(this.fequForm.fest_smpl_list[i].fequ_pedate) * 1000);
              } else {
                this.fequForm.fest_smpl_list[i].fequ_pedate = null;
              }
              if (this.fequForm.fest_smpl_list[i].fequ_smpl_redate !== 0) {
                this.fequForm.fest_smpl_list[i].fequ_smpl_redate = parseInt(Number(this.fequForm.fest_smpl_list[i].fequ_smpl_redate) * 1000);
              } else {
                this.fequForm.fest_smpl_list[i].fequ_smpl_redate = null;
              }
              if (!this.fequForm.fest_smpl_list[i].fequ_taker) {
                this.fequForm.fest_smpl_list[i].fequ_taker = null;
              }
              if (!this.fequForm.fest_smpl_list[i].fequ_pricer) {
                this.fequForm.fest_smpl_list[i].fequ_pricer = null;
              }
              this.fequForm.fest_smpl_list[i].fequ_smpl_cost = this.helper.haveFour(this.fequForm.fest_smpl_list[i].fequ_smpl_cost);
              this.fequForm.fest_smpl_list[i].fequ_smpl_recost = this.helper.haveFour(this.fequForm.fest_smpl_list[i].fequ_smpl_recost);
              this.fequForm.fest_smpl_list[i].fequ_smpl_num = this.helper.reservedDigits(this.fequForm.fest_smpl_list[i].fequ_smpl_num);
              //日期空值防护
              if (!this.fequForm.fest_smpl_list[i].requ_smpl_qudate) {
                this.fequForm.fest_smpl_list[i].requ_smpl_qudate = null;
              } else {
                this.fequForm.fest_smpl_list[i].requ_smpl_qudate = Number(this.fequForm.fest_smpl_list[i].requ_smpl_qudate) * 1000;
              }
              if (!this.fequForm.fest_smpl_list[i].requ_smpl_requdate) {
                this.fequForm.fest_smpl_list[i].requ_smpl_requdate = null;
              } else {
                this.fequForm.fest_smpl_list[i].requ_smpl_requdate = Number(this.fequForm.fest_smpl_list[i].requ_smpl_requdate) * 1000;
              }
              if (this.fequForm.fest_smpl_list[i].fequ_smpl_quodate) {
                this.fequForm.fest_smpl_list[i].fequ_smpl_quodate = parseInt(Number(this.fequForm.fest_smpl_list[i].fequ_smpl_quodate) * 1000);
              } else {
                this.fequForm.fest_smpl_list[i].fequ_smpl_quodate = null;
              }
            }
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let tp = 'error';
          this.$message({ message: res, type: tp });
        });
    },
    //同步至打样间
    downloadPdf() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          fequAPI.export_fequ_part({ fequ_id: this.fequForm.fequ_id, type: 0 }).then(({ data }) => {
            this.helper.downloadItem(data.pdf_url, `${data.fileName}.pdf`);
          });
        })
        .catch(err => console.error(err));
    },
    updateAttachmentsToDYJ() {
      openAPI.editRequDocu(this.fequForm).then(({ data }) => {
        this.$message.success('同步成功!');
      });
    },
    cancelSampling() {
      const uncancelledSamples = this.selectList
        .filter(item => item.sample_status !== 2)
        .map(item => item.prod_no)
        .join(', ');
      if (uncancelledSamples) return this.$message.warning(`以下样品外销暂未取消打样，不可取消打样：${uncancelledSamples}`);
      let requestBody = cloneDeep(this.fequForm);
      requestBody.fest_smpl_list = this.selectList;
      openAPI.editRequStatus(requestBody).then(({ data }) => {
        this.$message.success('取消打样成功!');
        this.initData();
      });
    },
    returnTheCommissionedSampleForm() {
      let selection = cloneDeep(this.$refs.RequList.selectionsList);
      if (selection.length === 0) return this.$message.warning('请选择数据!');
      if (selection.filter(x => x.sample_status !== 4).length > 0) return this.$message.warning('状态为打样间退回时才可退回委托单!');
      this.$prompt('请填写退回原因!', '确定退回?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        inputValue: this.fequForm.requ_DYJreason,
        inputType: 'textarea',
        inputValidator: value => (value ? true : '请填写退回原因')
      }).then(({ value }) => {
        let data = selection;
        data.forEach(item => (item.cancelReason = value));
        fequAPI.editReturnRequ(data).then(({ data }) => {
          this.$refs.RequList.$refs.requList.clearSelection();
          this.$message.success('取消打样成功!');
          this.initData();
        });
      });
    },
    fillSubTableDate() {
      if (!this.sampleSubmissionDate) return this.$message.warning('请选择填充交样日期!');
      let selection = this.$refs.RequList.selectionsList;
      if (selection.length === 0) return this.$message.warning('请选择数据!');
      selection.forEach(item => {
        item.fequ_smpl_redate = this.sampleSubmissionDate;
      });
      this.$message.success('填充成功!');
      if (this.$cookies.get('userInfo').dept_name === '辅料部') {
        if (getArrayIds(this.fequForm.fest_smpl_list, 'fequ_smpl_redate').findIndex(x => !x) === -1) this.fequForm.fequ_state = 2;
      }
      /* let cSelection = cloneDeep(selection);
      for (let i in cSelection) {
        cSelection[i].fequ_pedate = Number(new Date(cSelection[i].fequ_pedate).getTime() / 1000);
        cSelection[i].fequ_smpl_redate = this.sampleSubmissionDate;
        if (cSelection[i].fequ_smpl_redate) {
          cSelection[i].fequ_smpl_redate = Number(new Date(this.sampleSubmissionDate).getTime() / 1000);
        } else {
          cSelection[i].fequ_smpl_redate = 0;
        }
        if (!cSelection[i].fequ_taker) {
          cSelection[i].fequ_taker = 0;
        }
        if (!cSelection[i].fequ_pricer) {
          cSelection[i].fequ_pricer = 0;
        }
        //格式化要求核价日期
        if (cSelection[i].requ_smpl_qudate) {
          cSelection[i].requ_smpl_qudate = Number(new Date(cSelection[i].requ_smpl_qudate).getTime() / 1000);
        } else {
          cSelection[i].requ_smpl_qudate = 0;
        }
        //格式化实际核价日期
        if (cSelection[i].requ_smpl_requdate) {
          cSelection[i].requ_smpl_requdate = Number(new Date(cSelection[i].requ_smpl_requdate).getTime() / 1000);
        } else {
          cSelection[i].requ_smpl_requdate = 0;
        }
        if (cSelection[i].fequ_smpl_quodate) {
          cSelection[i].fequ_smpl_quodate = Number(new Date(cSelection[i].fequ_smpl_quodate).getTime() / 1000);
        } else {
          cSelection[i].fequ_smpl_quodate = 0;
        }
      }
      fequAPI.editFequSmole(cSelection).then(({ data }) => {
        this.$message.success('填充成功!');
        this.getfequInfo();
      });*/
    },
    fequSmplRedateClick(val) {
      if (val === 1) {
        this.fequForm.fequ_state = 2;
        //实际交样日期
        this.fequForm.fest_smpl_list.forEach(item => {
          item.fequ_smpl_redate = this.fequForm.fequ_smpl_redate;
        });
      } else {
        //实际核价日期
        this.fequForm.fest_smpl_list.forEach(item => {
          item.requ_smpl_requdate = this.fequForm.requ_smpl_requdate;
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_bort {
  border-top: dashed 1px #dcdfe6;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export1 {
  position: absolute;
  top: 0;
  left: 120px;
}

.vd_export3 {
  position: absolute;
  top: 0;
  left: 255px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 120px;
}

::v-deep .el-upload--text {
  height: 300px;
  width: 300px;
}

::v-deep .el-upload-dragger {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
