<template>
  <div class="vg_wrapper">
    <div class="vd_search_group"></div>
    <el-row class="vd_mar10">
      <el-col :md="16">
        <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
        <el-button class="vg_mr_8" size="mini" type="primary" @click="confirmIn()">确认选择</el-button>
      </el-col>
    </el-row>
    <el-row class="vd_mrt">
      <el-col :md="24">
        <el-table
          ref="multiTable"
          :data="tableData"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          v-loading="loading"
        >
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="设计单号" prop="desi_no" />
          <el-table-column label="产品编号" prop="prod_no"> </el-table-column>
          <el-table-column label="产品名称" prop="prod_name" />
          <el-table-column label="产品规格" prop="prod_spec" />
          <el-table-column label="产品单位" prop="prod_unit" show-overflow-tooltip> </el-table-column>
          <el-table-column label="打样接收人" prop="requ_smpl_taker_name" show-overflow-tooltip />
          <el-table-column label="核价接收人" prop="requ_smpl_pricer_name" show-overflow-tooltip />
          <!-- <el-table-column label="实际交样时间" prop="requ_deil_time" :formatter="formatDate1" show-overflow-tooltip/> -->
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <!-- <pubPagination ref="pubPagination" :totalPage = 'totalPage' @changePageSearch = "changePageSearch"></pubPagination> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { requAPI } from '@api/modules/requ';
// import pubPagination from "@/components/common/pubPagination";
import helper from '@assets/js/helper.js';

export default {
  name: 'RequEditBom',
  components: {
    // pubPagination,
  },
  props: {
    requNo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        requ_name: '',
        requ_no: '',
        requ_type: '',
        status: null
      },
      totalPage: 0,
      btn: {},
      loading: true,
      multiSelection: [],
      currentPage: 1,
      requType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ]
    };
  },
  created() {
    this.initData();
  },
  filters: {
    foamatRequPrice(row) {
      return helper.haveFour(row);
    },
    formatRequVerifyPrice(row) {
      return helper.haveFour(row);
    },
    formatRequNum(row) {
      return helper.haveFour(row);
    }
  },
  methods: {
    initData() {
      this.getRequsList();
    },
    // 获取物料信息
    getRequsList() {
      get(requAPI.getRequSmplByRequNo, {
        requ_no: this.requNo
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            this.loading = false;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 查询方法
    getRequsNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getRequsList();
    },
    // 刷新
    getRequsNowO() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      (this.searchForm = {
        requ_name: '',
        requ_no: '',
        requ_type: '',
        status: null
      }),
        this.getRequsList();
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.requ_smpl_id;
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getRequsList();
    },
    // 时间转换
    formatDate1(row) {
      return this.helper.toStringDate(row.requ_deil_time);
    },
    // 样品类型转换
    formatRequType(row) {
      if (row.requ_type === 1) {
        return '新款';
      } else if (row.requ_type === 2) {
        return '老款';
      } else if (row.requ_type === 3) {
        return '修改款';
      }
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childRequChanel');
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childRequ', this.multiSelection);
        this.clear();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_mrt {
  margin-top: 20px;
}
.vd_mar10 {
  margin: 10px 0;
}
</style>
