import topic from '../topic';
import { postNoCatch } from '@api/request';
export const qtucAPI = {
  getQutcs: topic.qtucTopic + '/get_qutcs',
  deleteQutcByIds: topic.qtucTopic + '/delete_qutc_by_ids',
  getQutcById: topic.qtucTopic + '/get_qutc_by_id',
  addQutc: topic.qtucTopic + '/add_qutc',
  editQutc: topic.qtucTopic + '/edit_qutc',
  getQutcSmpls: topic.qtucTopic + '/get_qutc_smpls',
  exportQutcPdfById: topic.qutcPdfTopic + '/export_qutc_pdf_by_id',
  delete_qutc_by_ids: params => postNoCatch(`${topic.qtucTopic}/delete_qutc_by_ids`, params)
};
