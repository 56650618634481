<template>
  <div>
    <el-row class="vg_mb_16">
      <el-button type="primary" size="small" plain @click="confirmClick">确定生成</el-button>
      <el-button size="small" plain @click="cancelClick">取消</el-button>
    </el-row>
    <el-row>
      <el-table
        ref="multipleTable"
        :data="fequFormList"
        :row-style="setRowStyle"
        style="width: 100%"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="序号" type="index" align="center"> </el-table-column>
        <el-table-column prop="dequ_no" label="委托打样单号">
          <template slot-scope="scope">
            <div>{{ requNo }}</div>
            <div style="display: none">{{ scope.$index }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="dequ_no" label="工厂打样单号" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>{{ fequNo }}</div>
            <div style="display: none">{{ scope.$index }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="prod_no" label="我司货号"></el-table-column>
        <el-table-column prop="prod_name" label="样品名称"></el-table-column>
        <el-table-column prop="fequ_smpl_num" label="样品数量" :formatter="formatterSmplNum"></el-table-column>
        <!--<el-table-column prop="fequ_smpl_quot" label="打样状态" :formatter="quotType"></el-table-column>-->
        <el-table-column prop="fequ_pedate" label="要求交样日期" :formatter="formatterSmplTime"></el-table-column>
        <el-table-column prop="fequ_taker" label="打样接收人" :formatter="formatterUser"></el-table-column>
        <el-table-column prop="fequ_pricer" label="核价接收人" :formatter="formatterUser1"></el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import { post } from '@api/request';
import { qutbAPI } from '@api/modules/qutb';
import { qtucAPI } from '@api/modules/qtuc';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'fequList',
  data() {
    return {
      selectList: [],
      smplQuotlist: [
        { id: 1, label: '打样并报价' },
        { id: 2, label: '试样报价' },
        { id: 3, label: '仅打样' },
        { id: 4, label: '估价' },
        { id: 5, label: '返工' }
      ]
    };
  },
  props: {
    fequFormList: {
      type: Array,
      required: true
    },
    userList: {
      type: Array,
      required: true
    },
    requNo: {
      type: String,
      required: true
    },
    fequNo: {
      type: String,
      required: true
    },
    type: {
      require: true
    }
  },
  methods: {
    // 确定生成
    confirmClick() {
      if (this.selectList.length > 0) {
        if (this.type === 1) {
          if (this.selectList.length > 1) {
            this.$message.warning('只能选择一条数据');
          } else {
            let params = {};
            params.fequ_smpl_id = this.selectList[0].fequ_smpl_id;
            params.user_id = this.$cookies.get('userInfo').user_id;
            params.stff_id = this.$cookies.get('userInfo').stff_id;
            params.dept_id = this.$cookies.get('userInfo').dept_id;
            params.dept_team_id = this.$cookies.get('userInfo').dept_team_id;
            post(qutbAPI.addQutb, params)
              .then(res => {
                if (res.data.code === 0) {
                  this.cancelClick();
                  setTimeout(() => {
                    this.jump('/qutb_edit', {
                      key: UrlEncode.encode(
                        JSON.stringify({
                          perm_id: 118,
                          form_id: res.data.data.form_id
                        })
                      )
                    });
                  }, 500);
                } else {
                  let mg = res.data.msg;
                  let tp = 'error';
                  this.$message({ message: mg, type: tp });
                }
              })
              .catch(() => {});
          }
        } else if (this.type === 2) {
          let obj = this.selectList[0];
          let flag = this.selectList.every(item => item.fequ_pricer === obj.fequ_pricer);
          if (!flag) {
            return this.$message.info('请选择同一个核价接收人');
          }

          let arr = [];
          this.selectList.forEach(item => {
            arr.push(item.fequ_smpl_id);
          });
          let str = '';
          str = arr.join(',');
          const params = {};
          if (this.selectList[0].fequ_sample_lint_id) {
            params.fequ_type = 1;
            params.fequ_sample_lint_id = this.selectList[0].fequ_sample_lint_id;
          } else {
            params.fequ_smpl_ids = str;
          }
          params.user_id = this.$cookies.get('userInfo').user_id;
          params.stff_id = this.$cookies.get('userInfo').stff_id;
          params.dept_id = this.$cookies.get('userInfo').dept_id;
          params.dept_team_id = this.$cookies.get('userInfo').dept_team_id;
          post(qtucAPI.addQutc, params)
            .then(res => {
              if (res.data.code === 0) {
                this.cancelClick();
                setTimeout(() => {
                  this.jump('/qutc_edit', {
                    key: UrlEncode.encode(
                      JSON.stringify({
                        perm_id: 120,
                        form_id: res.data.data.form_id
                      })
                    )
                  });
                }, 500);
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
              }
            })
            .catch(res => {});
        }
      } else {
        this.$message.warning('至少选择一条数据');
      }
    },
    // 回显状态
    quotType(val) {
      if (val.fequ_smpl_quot === 1) {
        return '打样并报价';
      } else if (val.fequ_smpl_quot === 2) {
        return '试样报价';
      } else if (val.fequ_smpl_quot === 3) {
        return '仅打样';
      } else if (val.fequ_smpl_quot === 4) {
        return '估价';
      } else if (val.fequ_smpl_quot === 5) {
        return '返工';
      }
    },
    // 取消生成
    cancelClick() {
      this.$emit('fequCancel');
    },
    // 勾选框选中
    handleSelectionChange(val) {
      this.selectList = val;
    },
    //数量保留位数
    formatterSmplNum(val) {
      return this.helper.reservedDigits(val.fequ_smpl_num);
    },
    // 计算日期
    formatterSmplTime(val) {
      return this.helper.toTimeDay(val.fequ_pedate / 1000);
    },
    formatterUser(val) {
      for (let i = 0; i < this.userList.length; i++) {
        if (val.fequ_taker === this.userList[i].stff_id) {
          return this.userList[i].stff_name;
        }
      }
    },
    formatterUser1(val) {
      for (let i = 0; i < this.userList.length; i++) {
        if (val.fequ_pricer === this.userList[i].stff_id) {
          return this.userList[i].stff_name;
        }
      }
    },
    setRowStyle({ row }) {
      if (row.sample_status === 1) {
        return { background: 'rgb(245,247,250)' };
      } else if (row.fequ_qutc_start === 1) {
        return { background: 'rgb(240,249,235)' };
      }
    }
  }
};
</script>

<style></style>
