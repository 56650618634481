<template>
  <el-form :disabled="false" label-width="120px" size="mini">
    <el-table ref="requList" :data="fequForm.fest_smpl_list" border @selection-change="handleSelectionChange">
      <el-table-column :fixed="true" align="center" type="selection" width="48"></el-table-column>
      <el-table-column :fixed="true" align="center" label="序号" width="48">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="样品状态" prop="sample_status" width="120" v-if="fequForm.requ_type === 1">
        <template v-slot="scope">
          <el-tag v-if="scope.row.sample_status === 0" type="success">正常打样</el-tag>
          <el-tag v-else-if="scope.row.sample_status === 1" type="warning">已退回</el-tag>
          <el-tag v-else-if="scope.row.sample_status === 2" type="info">待取消</el-tag>
          <el-tag v-else-if="scope.row.sample_status === 3" type="danger">取消打样</el-tag>
          <el-tag v-else-if="scope.row.sample_status === 4" type="warning">打样间退回</el-tag>
          <el-tag v-else type="info">暂无</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="产品图片" prop="picture_url" width="80" key="picture_url">
        <template v-slot="scope">
          <el-form-item :prop="'priceConsultPartList.' + scope.$index + '.picture_url'" label-width="0">
            <el-image
              v-if="fequForm.fest_smpl_list[scope.$index].picture_url"
              :previewSrcList="[fequForm.fest_smpl_list[scope.$index].picture_url]"
              :src="fequForm.fest_smpl_list[scope.$index].picture_url"
              fit="contain"
              placeholder="暂无产品图片"
            >
            </el-image>
            <span v-else>暂无</span>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="我司货号" width="150" key="prod_no">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.prod_no'" label-width="0">
            <el-input v-model="fequForm.fest_smpl_list[scope.$index].prod_no" disabled maxlength="10" placeholder="暂无我司货号" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="客户货号" width="150" key="prod_cust_no">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.prod_cust_no'" label-width="0">
            <el-input
              v-model="fequForm.fest_smpl_list[scope.$index].prod_cust_no"
              disabled
              maxlength="10"
              placeholder="暂无客户货号"
              show-word-limit
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="更改号" width="150" key="prod_suffix">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.prod_suffix'" label-width="0">
            <el-input v-model="fequForm.fest_smpl_list[scope.$index].prod_suffix" disabled maxlength="30" placeholder="暂无" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="老ERP货号" width="150" key="old_prod_cust_no">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.old_prod_cust_no'" label-width="0">
            <el-input v-model="fequForm.fest_smpl_list[scope.$index].old_prod_cust_no" disabled maxlength="20" placeholder="暂无" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="fequForm.requ_type === 3 || fequForm.requ_type === 2" key="fequ_smpl_cost" label="工厂报价" show-word-limit width="150">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.fequ_smpl_cost'" label-width="0">
            <el-input
              v-model="fequForm.fest_smpl_list[scope.$index].fequ_smpl_cost"
              :disabled="isShow"
              maxlength="9"
              placeholder="暂无工厂报价"
              show-word-limit
              @blur="fequForm.fest_smpl_list[scope.$index].fequ_smpl_cost = helper.calcPrice(fequForm.fest_smpl_list[scope.$index].fequ_smpl_cost, 4, 10000)"
              @input="fequForm.fest_smpl_list[scope.$index].fequ_smpl_cost = helper.keepTNum1(fequForm.fest_smpl_list[scope.$index].fequ_smpl_cost)"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="fequForm.requ_type === 3 || fequForm.requ_type === 2" key="fequ_smpl_recost" label="采购核价" width="150">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.fequ_smpl_recost'" label-width="0">
            <el-input
              v-model="fequForm.fest_smpl_list[scope.$index].fequ_smpl_recost"
              :disabled="isShow"
              maxlength="9"
              placeholder="暂无采购部核价"
              show-word-limit
              @blur="
                fequForm.fest_smpl_list[scope.$index].fequ_smpl_recost = helper.calcPrice(fequForm.fest_smpl_list[scope.$index].fequ_smpl_recost, 4, 10000)
              "
              @input="fequForm.fest_smpl_list[scope.$index].fequ_smpl_recost = helper.keepTNum1(fequForm.fest_smpl_list[scope.$index].fequ_smpl_recost)"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="prod_name" :label="fequForm.requ_type === 3 ? '产品名称' : '样品名称'" width="150">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.prod_name'" label-width="0">
            <el-input v-model="fequForm.fest_smpl_list[scope.$index].prod_name" disabled maxlength="20" placeholder="暂无" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="fequForm.requ_type === 3" label="模具编号" width="150">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.mould_no'" label-width="0">
            <el-input v-model="fequForm.fest_smpl_list[scope.$index].mould_no" disabled placeholder="暂无模具编号" size="mini"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="prod_spec" label="产品规格" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.prod_spec'" label-width="0">
            <el-input
              v-model="fequForm.fest_smpl_list[scope.$index].prod_spec"
              :disabled="isShow || fequForm.fequ_back_num === 0"
              :rows="2"
              maxlength="100"
              placeholder="暂无产品规格"
              show-word-limit
              type="textarea"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="fequForm.requ_type === 3" key="difficulty_level" align="center" label="难易等级" min-width="120px" prop="difficulty_level">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.difficulty_level'" label-width="0">
            <el-select v-model="fequForm.fest_smpl_list[scope.$index].difficulty_level" :disabled="isShow" filterable placeholder="请选择" size="small">
              <el-option v-for="item in difficultyLevel" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="fequ_smpl_num" label="样品数量" width="150">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.fequ_smpl_num'" label-width="0">
            <el-input
              v-model="fequForm.fest_smpl_list[scope.$index].fequ_smpl_num"
              disabled
              maxlength="10"
              placeholder="暂无样品数量"
              show-word-limit
              @input="fequForm.fest_smpl_list[scope.$index].fequ_smpl_num = helper.reservedDigits(fequForm.fest_smpl_list[scope.$index].fequ_smpl_num)"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="fequForm.requ_type === 3" key="prod_unit" label="样品单位" width="150">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.prod_unit'" label-width="0">
            <el-input v-model="fequForm.fest_smpl_list[scope.$index].prod_unit" disabled placeholder="暂无样品单位" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="prod_content" label="包装要求" width="150">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.prod_content'" label-width="0">
            <el-input
              v-model="fequForm.fest_smpl_list[scope.$index].prod_content"
              :disabled="isShow || (fequForm.requ_type === 1 && fequForm.fequ_back_num === 0)"
              :rows="2"
              maxlength="50"
              placeholder="暂无包装要求"
              show-word-limit
              type="textarea"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="fequ_smpl_type" width="150">
        <template slot="header">
          <span class="vg_deep_red">样品类型</span>
        </template>
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.fequ_smpl_type'" :rules="[{ required: true }]" label-width="0">
            <el-select
              v-model="fequForm.fest_smpl_list[scope.$index].fequ_smpl_type"
              :disabled="isShow || fequForm.requ_type === 1"
              filterable
              placeholder="请选择样品类型"
            >
              <el-option v-for="item in computedSmplType" :key="item.id" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="fequ_smpl_quot" width="150">
        <template slot="header">
          <span class="vg_deep_red">委托类型</span>
        </template>
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.fequ_smpl_quot'" :rules="[{ required: true }]" label-width="0">
            <el-select
              v-model="fequForm.fest_smpl_list[scope.$index].fequ_smpl_quot"
              :disabled="isShow || fequForm.requ_type === 1"
              filterable
              placeholder="请选择委托类型"
              @change="quotChange(scope.$index)"
            >
              <el-option v-for="item in computedSmplQuotlist" :key="item.id" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="样品类别" width="150" key="prod_type">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.prod_type'" label-width="0">
            <el-input v-model="fequForm.fest_smpl_list[scope.$index].prod_type" disabled placeholder="暂无" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="样品材质" width="150" key="fequ_smpl_made">
        <template slot="header">
          <span v-if="fequForm.requ_type === 3" class="vg_deep_red">样品材质</span>
          <span v-else>样品材质</span>
        </template>
        <template v-slot="scope">
          <el-form-item
            v-if="fequForm.requ_type === 3"
            :prop="'fest_smpl_list.' + scope.$index + '.fequ_smpl_made'"
            :rules="[{ required: true }]"
            label-width="0"
          >
            <el-select v-model="fequForm.fest_smpl_list[scope.$index].fequ_smpl_made" :disabled="isShow" filterable placeholder="请选择样品类型">
              <el-option v-for="item in fequSmplMadeList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-else :prop="'fest_smpl_list.' + scope.$index + '.fequ_smpl_made'" label-width="0">
            <el-input v-model="fequForm.fest_smpl_list[scope.$index].fequ_smpl_made" disabled placeholder="暂无" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        v-if="this.isShowfor5dept && fequForm.requ_type !== 3 && fequForm.requ_type !== 0"
        key="prod_ename"
        label="样品英文名"
        prop="prod_ename"
        width="150"
      >
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.prod_ename'" label-width="0">
            <el-input
              v-model="fequForm.fest_smpl_list[scope.$index].prod_ename"
              disabled
              maxlength="30"
              placeholder="暂无样品英文名"
              show-word-limit
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        v-if="this.isShowfor5dept && fequForm.requ_type !== 3 && fequForm.requ_type !== 0"
        key="series_name"
        label="样品系列名"
        prop="series_name"
        width="150"
      >
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.series_name'" label-width="0">
            <el-input
              v-model="fequForm.fest_smpl_list[scope.$index].series_name"
              disabled
              maxlength="30"
              placeholder="暂无样品系列名"
              show-word-limit
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        v-if="this.isShowfor5dept && fequForm.requ_type !== 3 && fequForm.requ_type !== 0"
        key="cust_num"
        label="客户数量"
        prop="cust_num"
        width="150"
      >
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.cust_num'" label-width="0">
            <el-input v-model="fequForm.fest_smpl_list[scope.$index].cust_num" disabled maxlength="30" placeholder="暂无客户数量" show-word-limit></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        v-if="fequForm.requ_type !== 3 && fequForm.requ_type !== 0"
        key="requ_sample_ask"
        :label="fequForm.requ_type === 2 && fequForm.order_type === 1 ? '材质工艺' : '样品要求'"
        width="150"
      >
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.requ_sample_ask'" label-width="0">
            <el-input
              v-model="fequForm.fest_smpl_list[scope.$index].requ_sample_ask"
              :disabled="isShow || fequForm.fequ_back_num === 0"
              maxlength="30"
              placeholder="暂无"
              show-word-limit
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="fequ_taker" label="打样接收人" prop="fequ_taker" width="150">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.fequ_taker'" label-width="0">
            <!-- <el-input  show-word-limit maxlength="9"  v-model="fequForm.fest_smpl_list[scope.$index].fequ_taker"  placeholder="暂无大样接收人"></el-input> -->
            <el-select
              v-model="fequForm.fest_smpl_list[scope.$index].fequ_taker"
              :disabled="isShow ? true : fequTakerDisabled()"
              filterable
              placeholder="暂无打样接收人"
            >
              <el-option v-for="item in userList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="fequForm.requ_type === 3" key="quote_stff_id" label="报价接收人" width="150">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.quote_stff_id'" label-width="0">
            <el-select v-model="fequForm.fest_smpl_list[scope.$index].quote_stff_id" :disabled="isShow" filterable placeholder="请选择报价接收人">
              <el-option v-for="item in userList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="fequForm.requ_type === 3" key="fequ_pricer" label="核价接收人" width="150">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.fequ_pricer'" label-width="0">
            <el-select v-model="fequForm.fest_smpl_list[scope.$index].fequ_pricer" filterable placeholder="请选择核价接收人" :disabled="isShow">
              <el-option
                v-for="item in userList.filter(item2 => item2.dept_id === fequForm.dept_id)"
                :key="item.stff_id"
                :label="item.stff_name"
                :value="item.stff_id"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="fequ_pedate" prop="fequ_pedate" width="150">
        <template slot="header">
          <span class="vg_deep_red">要求完成日期</span>
        </template>
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.fequ_pedate'" :rules="[{ required: true }]" label-width="0">
            <el-date-picker
              v-model="fequForm.fest_smpl_list[scope.$index].fequ_pedate"
              :disabled="isShow || fequForm.requ_type === 3"
              :picker-options="pickerOptions"
              placeholder="请选择要求完成日期"
              type="date"
              disabled
            >
            </el-date-picker>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="fequ_smpl_redate" label="实际完成日期" width="150">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.fequ_smpl_redate'" label-width="0">
            <el-date-picker
              v-model="fequForm.fest_smpl_list[scope.$index].fequ_smpl_redate"
              :disabled="isShow"
              placeholder="请选择实际完成日期"
              type="date"
              @change="updateQudate(scope.$index)"
            >
            </el-date-picker>
          </el-form-item>
        </template>
      </el-table-column>
      <!--      <el-table-column v-if="fequForm.requ_type !== 3 && fequForm.requ_type !== 0" key="fequ_smpl_quodate" label="要求报价日期" width="150">-->
      <!--        <template v-slot="scope">-->
      <!--          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.fequ_smpl_quodate'" label-width="0">-->
      <!--            <el-date-picker-->
      <!--              v-model="fequForm.fest_smpl_list[scope.$index].fequ_smpl_quodate"-->
      <!--              :disabled="isShow"-->
      <!--              placeholder="请选择要求报价日期"-->
      <!--              type="date"-->
      <!--            ></el-date-picker>-->
      <!--          </el-form-item>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column v-if="fequForm.requ_type === 1" key="quta_quote" label="DYJ报价" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.quta_quote'" label-width="0">
            <el-input v-model="fequForm.fest_smpl_list[scope.$index].quta_quote" disabled placeholder="暂无"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <!--      <el-table-column key="requ_smpl_qudate" label="要求核价日期" width="150">-->
      <!--        <template v-slot="scope">-->
      <!--          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.requ_smpl_qudate'" label-width="0">-->
      <!--            <el-date-picker-->
      <!--              v-model="fequForm.fest_smpl_list[scope.$index].requ_smpl_qudate"-->
      <!--              :disabled="isShow || fequForm.requ_type === 3"-->
      <!--              placeholder="请选择要求核价日期"-->
      <!--              type="date"-->
      <!--            >-->
      <!--            </el-date-picker>-->
      <!--          </el-form-item>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column key="requ_smpl_requdate" label="实际核价日期" width="150">-->
      <!--        <template v-slot="scope">-->
      <!--          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.requ_smpl_requdate'" label-width="0">-->
      <!--            <el-date-picker-->
      <!--              v-model="fequForm.fest_smpl_list[scope.$index].requ_smpl_requdate"-->
      <!--              :disabled="isShow"-->
      <!--              placeholder="请选择实际核价日期"-->
      <!--              type="date"-->
      <!--            ></el-date-picker>-->
      <!--          </el-form-item>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column key="fequ_pricing_num" label="核价份数" width="150">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.fequ_pricing_num'" label-width="0">
            <el-input
              v-model="fequForm.fest_smpl_list[scope.$index].fequ_pricing_num"
              :disabled="isShow"
              placeholder="暂无核价份数"
              show-word-limit
              @input="fequForm.fest_smpl_list[scope.$index].fequ_pricing_num = helper.pureNumber(fequForm.fest_smpl_list[scope.$index].fequ_pricing_num)"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="fequForm.requ_type === 1" key="quta_price" label="采购部核价" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.quta_price'" label-width="0">
            <el-input v-model="fequForm.fest_smpl_list[scope.$index].quta_price" disabled placeholder="暂无"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="fequ_smpl_remark" label="晚交原因" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.fequ_smpl_remark'" label-width="0">
            <CopyPasteCell
              :ref="`CopyPasteCell${scope.$index}`"
              :column="'fequ_smpl_remark'"
              :copy-data="scope.row.fequ_smpl_remark"
              :index="scope.$index"
              :is-show="isShow"
              :type="typeof scope.row.fequ_smpl_remark"
              @changeData="data => $refs[`CopyPasteCell${scope.$index}`].changeData({ list: fequForm.fest_smpl_list, ...data })"
            >
              <template v-slot:elForm>
                <el-input
                  v-model="fequForm.fest_smpl_list[scope.$index].fequ_smpl_remark"
                  :disabled="isShow"
                  :rows="2"
                  maxlength="50"
                  placeholder="暂无晚交原因"
                  show-word-limit
                  type="textarea"
                ></el-input>
              </template>
            </CopyPasteCell>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="remark" label="备注" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.remark'" label-width="0">
            <CopyPasteCell
              :ref="`CopyPasteCell${scope.$index}`"
              :column="'remark'"
              :copy-data="scope.row.remark"
              :index="scope.$index"
              :is-show="isShow"
              :type="typeof scope.row.remark"
              @changeData="data => $refs[`CopyPasteCell${scope.$index}`].changeData({ list: fequForm.fest_smpl_list, ...data })"
            >
              <template v-slot:elForm>
                <el-input
                  v-model="fequForm.fest_smpl_list[scope.$index].remark"
                  :disabled="isShow"
                  :rows="2"
                  maxlength="50"
                  placeholder="暂无备注"
                  show-word-limit
                  type="textarea"
                ></el-input>
              </template>
            </CopyPasteCell>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="business_back_num" label="退样次数" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.business_back_num'" label-width="0">
            <el-input v-model="fequForm.fest_smpl_list[scope.$index].business_back_num" disabled placeholder="暂无"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="business_back_reason" label="退样原因" width="200">
        <template v-slot="scope">
          <el-form-item :prop="'fest_smpl_list.' + scope.$index + '.business_back_reason'" label-width="0">
            <el-input
              v-model="fequForm.fest_smpl_list[scope.$index].business_back_reason"
              :rows="2"
              disabled
              maxlength="50"
              placeholder="暂无"
              show-word-limit
              type="textarea"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </el-form>
</template>

<script>
import { get } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { stffAPI } from '@/api/modules/staff';
import { getDateNoTime } from '@assets/js/dateUtils';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import CopyPasteCell from '@/components/table/CopyPasteCell.vue';
import { getArrayIds } from '@assets/js/arrayUtils';

export default {
  name: 'SmplEditBomChild',
  components: { CopyPasteCell, DynamicUTable },
  props: {
    fequForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedSmplType() {
      if (this.fequForm.requ_type === 1) return this.smplType1;
      if (this.fequForm.requ_type === 2) return this.smplType1;
      if (this.fequForm.requ_type === 3) return this.smplType;
      return [];
    },
    computedSmplQuotlist() {
      if (this.fequForm.requ_type === 1) return this.smplQuotlist;
      if (this.fequForm.requ_type === 2) return this.smplQuotlist1;
      if (this.fequForm.requ_type === 3) return this.smplQuotlist2;
      return [];
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e6;
        }
      },
      smplFlag: 0,
      selectionsList: [],
      smplType1: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ],
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' },
        { id: 4, label: '报价' },
        { id: 5, label: '其他' }
      ],
      selectFlag: false,
      beloType: [],
      smplQuotlist: [
        { id: 1, label: '打样并报价' },
        { id: 2, label: '试样报价' },
        { id: 3, label: '仅打样' },
        { id: 4, label: '估价' },
        { id: 5, label: '返工' },
        { id: 6, label: '仅报价' },
        { id: 7, label: '其他' }
      ],
      smplQuotlist1: [
        { id: 1, label: '打样并报价' },
        { id: 3, label: '仅打样' },
        { id: 6, label: '仅报价' },
        { id: 7, label: '其他' }
      ],
      smplQuotlist2: [
        { id: 1, label: '打样并报价' },
        { id: 3, label: '仅打样' },
        { id: 6, label: '仅报价' }
      ],

      fequSmplMadeList: [],
      userList: [],
      isShowfor5dept: false,
      difficultyLevel: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' }
      ]
    };
  },
  created() {
    this.initData();
    this.for5dept();
  },
  methods: {
    getDateNoTime,
    initData() {
      this.getProdTypeList();
      this.getUser();
    },
    getUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.userList = res.data.data;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    // 获取产品类别
    getProdTypeList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            this.fequSmplMadeList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    updateQudate(index) {
      if (this.$cookies.get('userInfo').dept_name === '辅料部') {
        if (getArrayIds(this.fequForm.fest_smpl_list, 'fequ_smpl_redate').findIndex(x => !x) === -1) this.fequForm.fequ_state = 2;
      }
      if (this.fequForm.requ_type === 3) return;
      this.fequForm.fest_smpl_list[index].requ_smpl_qudate = this.fequForm.fest_smpl_list[index].fequ_smpl_redate;
    },
    //选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionChange', this.selectionsList);
    },
    //只有业务五部能看到
    for5dept() {
      let { dept_id } = this.$cookies.get('userInfo');
      if (dept_id === 28) {
        this.isShowfor5dept = true;
      }
    },
    quotChange(index) {
      let fequSmplQuot = this.fequForm.fest_smpl_list[index].fequ_smpl_quot;
      if (this.fequForm.order_type === 0) {
        //有货号
        if (fequSmplQuot === 3) {
          //仅打样
          this.fequForm.fest_smpl_list[index].quote_stff_id = null; //报价接收人
          this.fequForm.fest_smpl_list[index].fequ_pricer = null; //核价接收人
        }
      } else {
        //无货号
        if (fequSmplQuot === 2) {
          //仅报价
          this.fequForm.fest_smpl_list[index].fequ_taker = null; //打样接收人
        } else if (fequSmplQuot === 8) {
          //仅打样
          this.fequForm.fest_smpl_list[index].quote_stff_id = null; //报价接收人
          this.fequForm.fest_smpl_list[index].fequ_pricer = null; //核价接收人
        }
      }
    },
    setRowStyle({ row }) {
      if (row.sample_status === 1) {
        return { background: 'rgb(245,247,250)' };
      } else if (row.fequ_qutc_start === 1) {
        return { background: 'rgb(103,194,58)' };
      }
    },
    fequTakerDisabled() {
      if (this.fequForm.requ_type === 3) {
        return false;
      } else if (this.fequForm.requ_type === 1 && this.$cookies.get('userInfo').stff_id === this.fequForm.stff_id) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style scoped></style>
